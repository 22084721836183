import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import config from './config';

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Fetch data from the backend API using the config
    fetch(`${config.API_URL}/api/test`)
      .then(response => response.json())
      .then(data => setMessage(data.message))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Threat Intelligence Portal</h1>
        <p>{message}</p>
      </header>
    </div>
  );
}

export default App;
